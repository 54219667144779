import { ReactElement } from 'react'
import { useDispatch } from 'react-redux';
import { setShowModalAds } from '@/store/ads/adsSlice';


import styles from './styles.module.scss'

export function AdsChest(): ReactElement {
  const dispatch = useDispatch();

  const handleClick = () =>{
    dispatch(setShowModalAds(true))
  }

  return (
    <button className={styles.ads} onClick={handleClick}>*Тут сундук*</button>
  )
}